import { InvestmentForSale } from "@/utils/api-client/models/investment/InvestmentForSale";
import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { Money } from "./models/common/Money";
import { UuidResult } from "./models/common/UuidResult";
import { FundRaisingProjectInvestment } from "./models/investment/FundRaisingProjectInvestment";
import { InvestmentConfirmation } from "./models/investment/InvestmentConfirmation";
import { LoanPaymentProjectInvestment } from "./models/investment/LoanPaymentProjectInvestment";
import {PaidProjectInvestment} from "@/utils/api-client/models/investment/PaidProjectInvestment";

export class InvestmentManager {
  constructor(private readonly apiClient: ApiClient) {}

  getListFundRaisingOwned(page = 1, perPage = 25): Promise<Collection<FundRaisingProjectInvestment>> {
    return this.apiClient.get(`/fund-raising/owned?page=${page}&perPage=${perPage}`);
  }

  getListLoanPaymentOwned(page = 1, perPage = 25): Promise<Collection<LoanPaymentProjectInvestment>> {
    return this.apiClient.get(`/loan-payment/owned?page=${page}&perPage=${perPage}`);
  }

  getListPaidOwned(page = 1, perPage = 15): Promise<Collection<PaidProjectInvestment>> {
    return this.apiClient.get(`/paid/owned?page=${page}&perPage=${perPage}`);
  }

  getListLoanPaymentGuaranteed(page = 1, perPage = 25): Promise<Collection<LoanPaymentProjectInvestment>> {
    return this.apiClient.get(`/loan-payment/guaranteed?page=${page}&perPage=${perPage}`);
  }

  getOneLoanPayment(uuid: string): Promise<InvestmentForSale> {
    return this.apiClient.get(`/loan-payment/${uuid}`);
  }

  create(confirmationUuid: string): Promise<UuidResult> {
    return this.apiClient.post("", {confirmationUuid});
  }

  cancel(uuid: string): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/cancel`);
  }

  getConfirmation(confirmationUuid: string, step: number): Promise<InvestmentConfirmation> {
    return this.apiClient.get(`/confirmation/${confirmationUuid}/step/${step}`);
  }

  createConfirmation(projectUuid: string, money: Money): Promise<UuidResult> {
    return this.apiClient.post("/confirmation", {projectUuid, money});
  }

  confirmStep(confirmationUuid: string, step: number, signature?: string): Promise<InvestmentConfirmation> {
    return this.apiClient.post(`/confirmation/${confirmationUuid}/confirm`, {step, signature});
  }
}
