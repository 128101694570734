import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { DetailedProject } from "./models/project/DetailedProject"
import { FundRaisingProject } from "./models/project/FundRaisingProject"
import { OwnedProject } from "./models/project/OwnedProject";
import { buildQuery } from "./utils";

type ListFilter = {
  interestRateFrom?: number;
  interestRateTo?: number;
  initialTermFrom?: number;
  initialTermTo?: number;
  ltvFrom?: number;
  ltvTo?: number;
  loanAmountFrom?: number;
  loanAmountTo?: number;
};

export class ProjectProvider {
  constructor(private readonly apiClient: ApiClient) {}

  getListOwned(page = 1, perPage = 25): Promise<Collection<OwnedProject>> {
    return this.apiClient.get(`/owned?page=${page}&perPage=${perPage}`);
  }

  getListFundRaising(filter: ListFilter, page = 1, perPage = 25): Promise<Collection<FundRaisingProject>> {
    return this.apiClient.get(`/fund-raising?${buildQuery(filter, "filter")}&page=${page}&perPage=${perPage}`);
  }

  getOne(uuid: string): Promise<DetailedProject> {
    return this.apiClient.get(`/${uuid}`);
  }
}
