import { UploadManager } from "../UploadManager";
import { ApiClientFactory } from "./clients";

export class UploadManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/upload`);
  }

  createUploadManager(authToken: string): UploadManager {
    return new UploadManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
