const i: any = window;
const s = document;
const o = "script";
const g = "https://www.googletagmanager.com/gtm.js?id=";
const r = "gtag";

export function initGoogleTagManager(id: string): void {
  i["dataLayer"] = i["dataLayer"] || [];
  i[r] = function (...data: Array<string>) {
    i["dataLayer"].push(data);
  };
  const script: any = s.createElement(o);
  const firstScript = s.getElementsByTagName(o)[0];
  script.async = 1;
  script.src = g + id;
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(script, firstScript)
  } else {
    s.appendChild(script);
  }
  i.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
}

export function configGoogleTagManager(id: string, userId?: string): void {
  if (userId) {
    i[r]("config", id, { "user_id": userId });
  } else {
    i[r]("config", id);
  }
}
