export default [
  { path: "", redirect: { name: "transactionList" }, name: "transactions" },
  {
    path: "list",
    component: (): Promise<{}> => import("../components/TransactionList.vue"),
    name: "transactionList",
  },
  {
    path: ":uuid",
    component: (): Promise<{}> => import("../components/TransactionDetails.vue"),
    name: "transactionDetails",
  }
];
