import { apiClientConfig } from "@/configs";
import * as apiClient from "@/utils/api-client";
import { AuthTokenManager } from "@/utils/auth/AuthTokenManager";
import { AuthTokenStorage } from "@/utils/auth/AuthTokenStorage";
import { LockFactory } from "@/utils/lock/LockFactory";

export function createAuthManager(): apiClient.AuthManager {
  const factory = new apiClient.AuthManagerFactory(apiClientConfig.baseUrl);
  return factory.createAuthManager();
}

export function createAuthTokenManager(): AuthTokenManager {
  return new AuthTokenManager(new AuthTokenStorage(), createAuthManager(), new LockFactory(window.localStorage));
}
