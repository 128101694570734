export default [
  { path: "", redirect: { name: "myInvestmentsList" }, name: "myInvestments" },
  {
    path: "list",
    component: (): Promise<{}> => import("../components/MyInvestmentsList.vue"),
    name: "myInvestmentsList",
  },
  {
    path: "sale/:uuid",
    component: (): Promise<{}> => import("../components/SaleInvestment.vue"),
    name: "saleInvestment",
  },
  {
    path: "sale/:uuid/confirmation/:confirmationUuid",
    component: (): Promise<{}> => import("../components/SaleConfirmation.vue"),
    name: "saleConfirmation",
  }
];
