import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { Money } from "./models/common/Money";
import { UuidResult } from "./models/common/UuidResult";
import { WithdrawRequest } from "./models/referral/WithdrawRequest";

export class ReferralWithdrawManager {
  constructor(private readonly apiClient: ApiClient) {}

  getList(page = 1, perPage = 25): Promise<Collection<WithdrawRequest>> {
    return this.apiClient.get(`?page=${page}&perPage=${perPage}`);
  }

  create(money: Money) {
    return this.apiClient.post("", {money});
  }

  cancel(uuid: string): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/cancel`);
  }
}
