import { UploadedFile } from "./models/upload/UploadedFile";
import { ApiClient } from "./clients";

export class UploadManager {
  constructor(private readonly apiClient: ApiClient) {}

  uploadFile(file: File): Promise<UploadedFile> {
    const formData = new FormData();
    formData.append("file", file);
    return this.apiClient.upload("/file", formData);
  }
}
