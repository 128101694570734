import { InvestmentManager } from "../InvestmentManager";
import { ApiClientFactory } from "./clients";

export class InvestmentManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/investment`);
  }

  createInvestmentManager(authToken: string): InvestmentManager {
    return new InvestmentManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
