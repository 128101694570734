import { ApiClient } from "@/utils/api-client/clients";
import { Collection } from "@/utils/api-client/models/common/Collection";
import { ArchivedProject } from "./models/project/ArchivedProject";
import { ProjectType } from "@/utils/api-client/enums/ProjectType";
import {buildQuery} from "@/utils/api-client/utils";

type ArchiveListFilter = {
    name?: string;
    projectType?: ProjectType;
};

export class ArchivedProjectProvider {
    constructor(private readonly apiClient: ApiClient) {}

    getList(filter:ArchiveListFilter, page = 1, perPage = 25): Promise<Collection<ArchivedProject>> {
        return this.apiClient.get(`?${buildQuery(filter, "filter")}&page=${page}&perPage=${perPage}`);
    }
}