import { ProjectRequestManager } from "../ProjectRequestManager";
import { ApiClientFactory } from "./clients";

export class ProjectRequestManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/project-request`);
  }

  createProjectRequestManager(authToken: string): ProjectRequestManager {
    return new ProjectRequestManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
