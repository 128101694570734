import { CallbackRequestManager } from "../CallbackRequestManager";
import { ApiClientFactory } from "./clients";

export class CallbackRequestManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/callback-request`);
  }

  createCallbackRequestManager(authToken: string): CallbackRequestManager {
    return new CallbackRequestManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken));
  }
}
