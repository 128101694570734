import { Agreement } from "@/utils/api-client/models/documents/Agreement";
import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";

export class AgreementProvider {
  constructor(private readonly apiClient: ApiClient) {}

  getList(page = 1, perPage = 25): Promise<Collection<Agreement>> {
    return this.apiClient.get(`?page=${page}&perPage=${perPage}`);
  }
}
