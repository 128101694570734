import { ApiClient } from "./clients";
import { MyMoney } from "./models/user/MyMoney";

export class MyMoneyProvider {
  constructor(private readonly apiClient: ApiClient) {}

  get(): Promise<MyMoney> {
    return this.apiClient.get("");
  }
}
