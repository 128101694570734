import { ReferralWithdrawManager } from "../ReferralWithdrawManager";
import { ApiClientFactory } from "./clients";

export class ReferralWithdrawManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/referral/withdraw`);
  }

  createReferralWithdrawManager(authToken: string): ReferralWithdrawManager {
    return new ReferralWithdrawManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
