import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { buildQuery } from "./utils";
import {FeeSettings} from "@/utils/api-client/models/fee-settings/FeeSettings";

type ListFilter = {
  projectUuids?: Array<string>
};

export class FeeSettingsProvider {
  constructor(private readonly apiClient: ApiClient) {}

  getList(filter: ListFilter, page = 1, perPage = 25): Promise<Collection<FeeSettings>> {
    return this.apiClient.get(`?${buildQuery(filter, "filter")}&page=${page}&perPage=${perPage}`);
  }

  getListForProject(projectUuid: string): Promise<Collection<FeeSettings>> {
    return this.apiClient.get(`${projectUuid}`);
  }
}
