import { OfferPurchaseManager } from "../OfferPurchaseManager";
import { ApiClientFactory } from "./clients";

export class OfferPurchaseManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/offer`);
  }

  createOfferPurchaseManager(authToken: string): OfferPurchaseManager {
    return new OfferPurchaseManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
