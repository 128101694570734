export default [
  { path: "", redirect: { name: "myProjectRequests.list" }, name: "myProjectRequests" },
  {
    path: "list",
    component: (): Promise<{}> => import("../components/List.vue"),
    name: "myProjectRequests.list",
    meta: { withSubMenu: true }
  },
  {
    path: "create",
    component: (): Promise<{}> => import("../components/Create.vue"),
    name: "myProjectRequests.create"
  },
  {
    path: ":uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: (): Promise<{}> => import("../components/request/Request.vue"),
    children: [
      { path: "", redirect: { name: "myProjectRequests.request.general" }, name: "myProjectRequest" },
      {
        path: "general",
        component: (): Promise<{}> => import("../components/request/components/General.vue"),
        name: "myProjectRequests.request.general",
        meta: { withSubMenu: true }
      },
      {
        path: "ownership",
        component: (): Promise<{}> => import("../components/request/components/Ownership.vue"),
        name: "myProjectRequests.request.ownership",
        meta: { withSubMenu: true }
      },
      {
        path: "collaterals",
        component: (): Promise<{}> => import("../components/request/components/Collaterals.vue"),
        name: "myProjectRequests.request.collaterals",
        meta: { withSubMenu: true }
      },
      {
        path: "documents",
        component: (): Promise<{}> => import("../components/request/components/Documents.vue"),
        name: "myProjectRequests.request.documents",
        meta: { withSubMenu: true }
      }
    ]
  },
  {
    path: ":uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/publishing",
    component: (): Promise<{}> => import("../components/Publishing.vue"),
    name: "myProjectRequests.publishing"
  }
];
