import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { Money } from "./models/common/Money";
import { UuidResult } from "./models/common/UuidResult";
import { ForSaleProjectOffer } from "./models/offer/ForSaleProjectOffer";
import { SaleConfirmation } from "./models/offer/SaleConfirmation";

export class OfferSaleManager {
  constructor(private readonly apiClient: ApiClient) {}

  getListForSale(page = 1, perPage = 100): Promise<Collection<ForSaleProjectOffer>> {
    return this.apiClient.get(
      `/for-sale?page=${page}&perPage=${perPage}`
    );
  }

  create(confirmationUuid: string, smsCode: string): Promise<UuidResult> {
    return this.apiClient.post("", {confirmationUuid, smsCode});
  }

  cancel(uuid: string): Promise<Collection<ForSaleProjectOffer>> {
    return this.apiClient.post(`/${uuid}/cancel`);
  }

  getConfirmation(confirmationUuid: string): Promise<SaleConfirmation> {
    return this.apiClient.get(`/confirmation/${confirmationUuid}`);
  }

  createConfirmation(
    parentInvestmentUuid: string,
    investmentMoney: Money,
    price: Money,
    expiredAt: Date,
    splittable: boolean,
    guarantorPercent?: number
  ): Promise<UuidResult> {
    return this.apiClient.post("/confirmation", {parentInvestmentUuid, investmentMoney, price, expiredAt, splittable, guarantorPercent});
  }

  sendConfirmationCode(confirmationUuid: string): Promise<SaleConfirmation> {
    return this.apiClient.post(`/confirmation/${confirmationUuid}/send-code`);
  }
}
