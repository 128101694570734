import { FeeSettingsProvider } from "../FeeSettingsProvider";
import { ApiClientFactory } from "./clients";

export class FeeSettingsFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/fee-settings`);
  }

  createFeeSettingsProvider(authToken: string): FeeSettingsProvider {
    return new FeeSettingsProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
