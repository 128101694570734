import { RepaymentProvider } from "../RepaymentProvider";
import { ApiClientFactory } from "./clients";

export class RepaymentProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/repayment`);
  }

  createRepaymentProvider(authToken: string): RepaymentProvider {
    return new RepaymentProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
