import { ProjectProvider } from "../ProjectProvider";
import { ApiClientFactory } from "./clients";

export class ProjectProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/project`);
  }

  createProjectProvider(authToken: string): ProjectProvider {
    return new ProjectProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
