export enum ProjectStatus {
  REVIEW = "REVIEW",
  REVIEW_APPROVED = "REVIEW_APPROVED",
  REVIEW_REJECTED = "REVIEW_REJECTED",
  FINAL_REVIEW = "FINAL_REVIEW",
  FINAL_REVIEW_APPROVED = "FINAL_REVIEW_APPROVED",
  FINAL_REVIEW_REJECTED = "FINAL_REVIEW_REJECTED",
  FUND_RAISING = "FUND_RAISING",
  FINISHED = "FINISHED",
  LOAN_PAYMENT = "LOAN_PAYMENT",
  PAID = "PAID",
  REFUNDED = "REFUNDED"
}
