import { PubSub } from "@/utils/pub-sub";
import { App } from "@vue/runtime-core";

enum NotificationType {
  INFO = "info",
  WARNING = "warning",
  ALERT = "alert"
}

type Message = {
  type: NotificationType;
  message: string;
}

type Callback = (message: Message) => void;

class Notifications {
  private readonly pubSub: PubSub<Message>;

  constructor() {
    this.pubSub = new PubSub<Message>();
  }

  addAlert(message: string): void {
    this.add(NotificationType.ALERT, message);
  }

  add(type: NotificationType, message: string): void {
    this.pubSub.publish({type, message});
  }

  subscribe(callback: Callback): string {
    return this.pubSub.subscribe(callback);
  }

  unsubscribe(token: string): void {
    this.pubSub.unsubscribe(token);
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$notifications = new Notifications();
  }
};
