import { ConfirmPlugin } from "@/components/confirm";
import { NotificationsPlugin } from "@/components/notifications";
import { sentryConfig } from "@/configs";
import { Unauthorized } from "@/utils/api-client";
import { AuthTokenExpired } from "@/utils/errors";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import "bootstrap";
import PrimeVue from "primevue/config";
import { createApp } from "vue";
import "./app.scss";
import App from "./App.vue";
import AnalyticsPlugin from "./plugins/analytics";
import router from "./router";

if (sentryConfig.dsn) {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: sentryConfig.environment,
    release: sentryConfig.release,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const app = createApp(App, { router })
  .use(router)
  .use(ConfirmPlugin)
  .use(NotificationsPlugin)
  .use(PrimeVue)
  .use(AnalyticsPlugin);

const errorHandler = app.config.errorHandler;
app.config.errorHandler = (err, vm, info) => {
  if (err instanceof AuthTokenExpired || err instanceof Unauthorized) {
    sessionStorage.setItem("redirectTo", vm?.$route.path as string);
    vm?.$router.replace({ name: "login" });
  } else {
    console.error(err);
  }
  errorHandler && errorHandler(err, vm, info);
};

app.mount("#app");
