import { DayRepaymentProvider } from "../DayRepaymentProvider";
import { ApiClientFactory } from "./clients";

export class DayRepaymentProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/day-repayment`
    );
  }

  createDayRepaymentProvider(authToken: string): DayRepaymentProvider {
    return new DayRepaymentProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
