export default [
  { path: "", redirect: { name: "myProjectList" }, name: "myProjects" },
  {
    path: "list",
    component: (): Promise<{}> => import("../components/MyProjectList.vue"),
    name: "myProjectList",
    meta: { withSubMenu: true }
  },
  {
    path: ":uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: (): Promise<{}> => import("../components/MyProject.vue"),
    name: "myProject"
  }
];
