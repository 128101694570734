import transactionsRoutes from "../components/transactions/router";

export default [
  {
    path: "transactions",
    component: (): Promise<{}> => import("../components/transactions/Transactions.vue"),
    children: transactionsRoutes
  },
  {
    path: "profile",
    component: (): Promise<{}> => import("../components/Profile.vue"),
    name: "profile"
  },
  {
    path: "notifications",
    component: (): Promise<{}> => import("../components/Notifications.vue"),
    name: "notifications"
  },
  {
    path: "logout",
    component: (): Promise<{}> => import("../components/Logout.vue"),
    name: "logout"
  },
  {
    path: "rules-confirmation",
    component: (): Promise<{}> => import("../components/RulesConfirmation.vue"),
    name: "rules-confirmation"
  }
];
