import axios, {
  AxiosInstance,
  AxiosRequestConfig
} from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import {
  PropertyError,
  Unauthorized,
  Forbidden,
  ValidationError
} from "../errors";
import {OutdatedPassword} from "@/utils/api-client/errors/OutdatedPassword";

type ClientOptions = {
  cache: boolean;
};

export class ApiClient {
  constructor(
    private readonly baseUrl: string,
    private readonly authToken: string = ""
  ) {
  }

  async get(endpoint: string, cache = false): Promise<any> {
    try {
      const client = this.createClient({ cache });
      const response = await client.get(endpoint);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(endpoint: string, data?: any): Promise<any> {
    try {
      const client = this.createClient();
      const response = await client.post(endpoint, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async upload(endpoint: string, formData: FormData): Promise<any> {
    try {
      const client = this.createClient();
      const response = await client.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return response.data;
    }  catch (error) {
      this.handleError(error);
    }
  }

  private createClient(options?: ClientOptions): AxiosInstance {
    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl
    };

    if (this.authToken) {
      config.headers = {
        Authorization: `Bearer ${this.authToken}`
      };
    }

    if (axios.defaults.adapter !== undefined) {
      config.adapter = cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: options && options.cache
      });
    }

    return axios.create(config);
  }

  private handleError(error: any): never {
    if (error.response.status === 400) {
      const errors: Array<any> = error.response.data.errors instanceof Array ? error.response.data.errors : [];
      throw new ValidationError(
        error.response.data.message,
        errors
          .filter((item) => item && item.propertyPath && item.message)
          .map((item) => new PropertyError(item.propertyPath, item.message)),
        error
      );
    } else if (error.response.status === 401 && error.response.data.message === "Password must be changed") {
      throw new OutdatedPassword();
    } else if (error.response.status === 401) {
      throw new Unauthorized(error.response.data);
    } else if (error.response.status === 403) {
      throw new Forbidden(error.response.data);
    } else {
      throw error;
    }
  }
}
