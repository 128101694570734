import { OfferSaleManager } from "../OfferSaleManager";
import { ApiClientFactory } from "./clients";

export class OfferSaleManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/offer`);
  }

  createOfferSaleManager(authToken: string): OfferSaleManager {
    return new OfferSaleManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
