import { Company } from "@/utils/api-client/models/company/Company";
import { ApiClient } from "./clients";

export class CompanyProvider {
  constructor(private readonly apiClient: ApiClient) {}

  suggestions(query?: string): Promise<Array<Company>> {
    return this.apiClient.get(`/suggestions?query=${query}`);
  }
}
