export default [
  { path: "", redirect: { name: "primaryMarket" }, name: "invest" },
  {
    path: "projects",
    component: (): Promise<{}> => import("../components/PrimaryMarket.vue"),
    name: "primaryMarket",
    meta: { withSubMenu: true }
  },
  {
    path: "projects/:uuid",
    component: (): Promise<{}> => import("../components/PrimaryMarketProject.vue"),
    name: "primaryMarketProject"
  },
  {
    path: "offers",
    component: (): Promise<{}> => import("../components/SecondaryMarket.vue"),
    name: "secondaryMarket",
    meta: { withSubMenu: true }
  },
  {
    path: "buy-back",
    component: (): Promise<{}> => import("../components/BuyBackOffers.vue"),
    name: "buyBackOffers",
    meta: { withSubMenu: true }
  },
  {
    path: "projects/:uuid/confirmation/:confirmationUuid/step/:step",
    component: (): Promise<{}> => import("../components/InvestmentConfirmation.vue"),
    name: "investmentConfirmation"
  },
  {
    path: "offers/:uuid",
    component: (): Promise<{}> => import("../components/Offer.vue"),
    name: "secondaryMarketOffer",
  },
  {
    path: "buy-back/:uuid",
    component: (): Promise<{}> => import("../components/Offer.vue"),
    name: "buyBackOffer",
  },
  {
    path: "offers/:uuid/confirmation/:confirmationUuid",
    component: (): Promise<{}> => import("../components/PurchaseConfirmationPrimary.vue"),
    name: "purchaseConfirmation",
  },
  {
    path: "buy-back/:uuid/confirmation/:confirmationUuid",
    component: (): Promise<{}> => import("../components/PurchaseConfirmationBuyBack.vue"),
    name: "purchaseByBackConfirmation",
  }
];
