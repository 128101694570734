import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { DetailedOffer } from "./models/offer/DetailedOffer";
import { SellingOffer } from "./models/offer/SellingOffer";
import { buildQuery } from "./utils";

type ListFilter = {
  interestRateFrom?: number;
  interestRateTo?: number;
  initialTermFrom?: number;
  initialTermTo?: number;
  ltvFrom?: number;
  ltvTo?: number;
  loanAmountFrom?: number;
  loanAmountTo?: number;
};

export class OfferProvider {
  constructor(private readonly apiClient: ApiClient) {}

  getListSelling(buyBack: boolean, filter: ListFilter, page = 1, perPage = 25): Promise<Collection<SellingOffer>> {
    return this.apiClient.get(
      `/selling?buyBack=${buyBack}&${buildQuery(filter, "filter")}&page=${page}&perPage=${perPage}`
    );
  }

  getListProjectSelling(projectUuid: string, page = 1, perPage = 25): Promise<Collection<SellingOffer>> {
    return this.apiClient.get(
      `/selling/${projectUuid}?page=${page}&perPage=${perPage}`
    );
  }

  getOne(uuid: string): Promise<DetailedOffer> {
    return this.apiClient.get(`/${uuid}`);
  }
}
