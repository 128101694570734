const i: any = window;
const s = document;
const o = "script";
const g = "https://www.google-analytics.com/analytics.js";
const r = "ga";

export function initGoogleAnalytics(id: string): void {
  i["GoogleAnalyticsObject"] = r;
  i[r] = i[r] || function (...data: Array<string>) {
    (i[r].q = i[r].q || []).push(data)
  };
  i[r].l = +new Date();
  const a: any = s.createElement(o);
  const m = s.getElementsByTagName(o)[0];
  a.async = 1;
  a.src = g;
  if (m && m.parentNode) {
    m.parentNode.insertBefore(a, m)
  } else {
    s.appendChild(a);
  }
  i[r]("create", id, "auto");
}

export function addGoogleAnalyticsEvent(name: string, ...data: Array<string>): void {
  i[r](name, ...data);
}
