import { NotificationSettingsManager } from "../NotificationSettingsManager";
import { ApiClientFactory } from "./clients";

export class NotificationSettingsManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/notification-settings`);
  }

  createNotificationSettingsManager(authToken: string): NotificationSettingsManager {
    return new NotificationSettingsManager(this.apiClientFactory.createAuthorizedApiClient(authToken));
  }
}
