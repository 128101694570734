import { TransactionProvider } from "../TransactionProvider";
import { ApiClientFactory } from "./clients";

export class TransactionProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/transaction`);
  }

  createTransactionProvider(authToken: string): TransactionProvider {
    return new TransactionProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
