<template>
  <div class="confirm-modal">
    <div v-if="show" class="modal fade show d-block" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-group">
              {{ message }}
            </div>
            <div class="form-group">
              <button @click="approve" type="button" class="btn btn-primary">Подтвердить</button>
              <button @click="reject" type="button" class="btn btn-secondary ml-3" data-dismiss="modal">Отмена</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  data() {
    return {
      show: false,
      message: null,
      resolve: null
    };
  },
  mounted() {
    this.confirmToken = this.$confirm.subscribe(({ message, resolve }) => {
      this.message = message;
      this.resolve = resolve;
      this.show = true;
    });
  },
  unmounted() {
    this.$confirm.unsubscribe(this.confirmToken);
  },
  methods: {
    approve() {
      this.show = false;
      this.resolve && this.resolve();
    },
    reject() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss">
.confirm-modal {
  .modal-dialog {
    width: 80%;
    margin: 0 auto;
    top: 40%;

    @media (min-width: 576px) { // Bootstrap SM
      width: auto;
    }

    .modal-content {
      .modal-body {
        text-align: center;

        button {
          width: 8rem;
        }
      }
    }
  }
}
</style>
