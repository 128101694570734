import { ApiClient } from "./clients";
import { Money } from "./models/common/Money";
import { UuidResult } from "./models/common/UuidResult";
import {Collection} from "@/utils/api-client/models/common/Collection";
import {WithdrawRequisites} from "@/utils/api-client/models/withdraw/WithdrawRequisites";

export class WithdrawManager {
  constructor(private readonly apiClient: ApiClient) {}

  create(money: Money, selectedRequisitesUuid?: string, purpose?: string) {
    return this.apiClient.post("", {money, selectedRequisitesUuid, purpose});
  }

  cancel(uuid: string): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/cancel`);
  }

  getList():  Promise<Collection<WithdrawRequisites>>{
    return this.apiClient.get(`/requisites`)
  }
}
