<template>
  <div class="notifications">
    <div class="block-list">
    <div v-for="(item, index) in messages">
      <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header bg-danger text-white">
          <strong class="mr-auto">Ошибка</strong>
          <button @click="removeMessage(index)" type="button" class="ml-2 mb-1 close">
            <i class="bi-x small text-white" aria-hidden="true"></i>
          </button>
        </div>
        <div class="toast-body text-danger">
          {{ item.message }}
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      messages: []
    };
  },
  mounted() {
    this.notificationsToken = this.$notifications.subscribe((message) => this.messages.push(message));
  },
  unmounted() {
    this.$notifications.unsubscribe(this.notificationsToken);
  },
  methods: {
    removeMessage(index) {
      this.messages.splice(index, 1);
    }
  }
}
</script>

<style lang="scss">
.notifications {
  position: relative;

  .block-list {
    position: fixed;
    bottom: 0;
    right: 0;

    .toast {
      margin: 0.5rem 1rem 0.5rem 0;
      width: 20rem;

      .toast-body {
        font-size: 1rem;
      }
    }
  }
}
</style>
