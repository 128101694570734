import { ApiClient } from "./clients";
import { Money } from "./models/common/Money";
import { UuidResult } from "./models/common/UuidResult";
import { PurchaseConfirmation } from "./models/offer/PurchaseConfirmation";

export class OfferPurchaseManager {
  constructor(private readonly apiClient: ApiClient) {}

  create(uuid: string, confirmationUuid: string, smsCode: string): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/purchase`, {confirmationUuid, smsCode});
  }

  getConfirmation(uuid: string, confirmationUuid: string): Promise<PurchaseConfirmation> {
    return this.apiClient.get(`/${uuid}/purchase/confirmation/${confirmationUuid}`);
  }

  createConfirmation(uuid: string, partialInvestmentMoney?: Money): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/purchase/confirmation`, {partialInvestmentMoney});
  }

  sendConfirmationCode(uuid: string, confirmationUuid: string): Promise<PurchaseConfirmation> {
    return this.apiClient.post(`/${uuid}/purchase/confirmation/${confirmationUuid}/send-code`);
  }
}
