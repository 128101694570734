import investRoutes from "../components/invest/router";
import myInvestmentsRoutes from "../components/my-investments/router";
import {Role} from "@/utils/api-client";

export default [
  {
    path: "my-money",
    component: (): Promise<{}> => import("../components/MyMoney.vue"),
    name: "myMoney",
    meta: { role: Role.LENDER }
  },
  {
    path: "invest",
    component: (): Promise<{}> => import("../components/invest/Invest.vue"),
    children: investRoutes,
    meta: { role: Role.LENDER }
  },
  {
    path: "my-investments",
    component: (): Promise<{}> => import("../components/my-investments/MyInvestments.vue"),
    children: myInvestmentsRoutes,
    meta: { role: Role.LENDER }
  },
  {
    path: "repayment-schedules",
    component: (): Promise<{}> => import("../components/repayment-schedules/RepaymentSchedules.vue"),
    name: "repaymentSchedules",
    meta: { role: Role.LENDER }
  },
  {
    path: "referral-income",
    component: (): Promise<{}> => import("../components/referral-income/ReferralIncome.vue"),
    name: "referralIncome",
    meta: { role: Role.LENDER }
  },
  {
    path: "deposit",
    component: (): Promise<{}> => import("../components/Deposit.vue"),
    name: "deposit",
    meta: { role: Role.LENDER }
  },
  {
    path: "withdraw",
    component: (): Promise<{}> => import("../components/Withdraw.vue"),
    name: "withdraw",
    meta: { role: Role.LENDER }
  },
  {
    path: "authorization",
    component: (): Promise<{}> => import("../components/authorization/Authorization.vue"),
    name: "authorization",
    meta: { role: Role.LENDER }
  },
  {
    path: "my-documents",
    component: (): Promise<{}> => import("../components/my-documents/MyDocuments.vue"),
    name: "myDocuments",
    meta: { role: Role.LENDER }
  },
  {
    path: "archived-projects",
    component: (): Promise<{}> => import("../components/archived/ArchivedProjects.vue"),
    name: "archivedProjects",
    meta: { role: Role.LENDER }
  },
  {
    path: "archived-projects/:uuid",
    component: (): Promise<{}> => import("../components/archived/ArchivedProjectDetails.vue"),
    name: "archivedProjectDetails",
    meta: { role: Role.LENDER }
  }
];
