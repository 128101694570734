export default {
  get googleAnalyticsId(): string {
    return process.env.VUE_APP_GOOGLE_ANALYTICS_ID || "";
  },
  get googleTagManagerId(): string {
    return process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID || "";
  },
  get yandexMetrikaId(): string {
    return process.env.VUE_APP_YANDEX_METRIKA_ID || "";
  }
};
