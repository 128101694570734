<template>
  <div class="app">
    <router-view></router-view>
    <confirm-modal/>
    <notifications/>
  </div>
</template>

<script>
import { ConfirmModal } from "@/components/confirm";
import { Notifications } from "@/components/notifications";
import { captureException } from "@sentry/browser";

export default {
  name: "App",
  components: {
    ConfirmModal,
    Notifications
  },
  errorCaptured(err) {
    captureException(err);
    throw err;
  }
}
</script>
