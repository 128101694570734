export function buildQuery(data: { [key: string]: any }, prefix?: string): string {
  const str: Array<string> = [];
  if (data instanceof Array) {
    data.forEach((value) => {
      const key: string = prefix + "[]";
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    });
  } else if (data !== null && typeof data === "object") {
    Object.keys(data).forEach((name) => {
      const key: string = prefix ? prefix + "[" + name + "]" : name;
      let value: any = data[name];
      if (value instanceof Date) {
        value = value.toISOString();
      }
      if (value !== null && typeof value === "object") {
        const res = buildQuery(value, key);
        res && str.push(res);
      } else if (value !== null && value !== "") {
        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
      }
    });
  }
  return str.join("&");
}
