import { AuthManager } from "../AuthManager";
import { ApiClientFactory } from "./clients";

export class AuthManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/auth`);
  }

  createAuthManager(): AuthManager {
    return new AuthManager(this.apiClientFactory.createApiClient());
  }
}
