import { WithdrawManager } from "../WithdrawManager";
import { ApiClientFactory } from "./clients";

export class WithdrawManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/withdraw`);
  }

  createWithdrawManager(authToken: string): WithdrawManager {
    return new WithdrawManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
