import { ApiClient } from "./clients";

export type Visit = {
  readonly uuid: string;
  readonly userAgent: string;
  readonly url: string;
  readonly referrer?: string;
  readonly createdAt: string;
};

export class VisitManager {
  constructor(private readonly apiClient: ApiClient) {}

  async addVisits(...visits: Array<Visit>): Promise<void> {
    if (visits.length > 0) {
      return this.apiClient.post("", visits);
    }
  }
}
