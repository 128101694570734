export enum OperationType {
  // Credit types
  REGISTER = "REGISTER",
  REMOVE_REGISTERED = "REMOVE_REGISTERED",
  ENROLL = "ENROLL",

  // Debit types
  RESERVE = "RESERVE",
  REFUND_RESERVED = "REFUND_RESERVED",
  CHARGE = "CHARGE"
}
