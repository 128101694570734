import { ReferralManager } from "../ReferralManager";
import { ApiClientFactory } from "./clients";

export class ReferralManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/referral`);
  }

  createReferralManager(authToken: string): ReferralManager {
    return new ReferralManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
