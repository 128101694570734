import {
  AuthManager,
  BearerToken,
  Unauthorized
} from "@/utils/api-client";
import { AuthTokenExpired } from "@/utils/errors";
import { LockFactory } from "@/utils/lock/LockFactory";
import moment from "moment";
import { AuthTokenStorage } from "./AuthTokenStorage";

export class AuthTokenManager {
  constructor(
    private readonly authTokenStorage: AuthTokenStorage,
    private readonly authManager: AuthManager,
    private readonly lockFactory: LockFactory
  ) {}

  async has(): Promise<boolean> {
    const authToken = await this.resolve();
    return authToken !== null;
  }

  async get(): Promise<string> {
    const authToken = await this.resolve();
    if (!authToken) {
      throw new AuthTokenExpired();
    }
    return authToken;
  }

  create(bearerToken: BearerToken): void {
    this.authTokenStorage.set(bearerToken);
  }

  remove(): void {
    this.authTokenStorage.remove();
  }

  private async resolve(): Promise<string | null> {
    const lock = this.lockFactory.createLock("auth-token:resolve");
    try {
      await lock.acquire();

      const bearerToken = this.authTokenStorage.get();
      if (!bearerToken) {
        return null;
      }

      if (moment(bearerToken.accessTokenExpiredAt).diff(moment()) > 0) {
        return bearerToken.accessToken;
      }

      return this.refresh();
    } finally {
      lock.release();
    }
  }

  private async refresh(): Promise<string | null> {
    const authToken = this.authTokenStorage.get();
    if (!authToken) {
      return null;
    }

    if (moment(authToken.refreshTokenExpiredAt).diff(moment()) <= 0) {
      return null;
    }

    let bearerToken;
    try {
      bearerToken = await this.authManager.refresh(authToken.refreshToken);
    } catch (err) {
      if (err instanceof Unauthorized) {
        return null;
      }
      throw err;
    }

    this.authTokenStorage.set(bearerToken);

    return bearerToken.accessToken;
  }
}
