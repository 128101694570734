import { VisitManager } from "../VisitManager";
import { ApiClientFactory } from "./clients";

export class VisitManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/visit`);
  }

  createVisitManager(authToken?: string): VisitManager {
    const apiClient = authToken ? this.apiClientFactory.createAuthorizedApiClient(authToken) : this.apiClientFactory.createApiClient();
    return new VisitManager(apiClient);
  }
}
