import { Role } from "@/utils/api-client";
import myProjectRequestsRoutes from "../components/my-project-requests/router";
import myProjectsRoutes from "../components/my-projects/router";

export default [
  {
    path: "my-projects",
    component: (): Promise<{}> => import("../components/my-projects/MyProjects.vue"),
    children: myProjectsRoutes,
    meta: { role: Role.BORROWER }
  },
  {
    path: "my-project-requests",
    component: (): Promise<{}> => import("../components/my-project-requests/MyProjectRequests.vue"),
    children: myProjectRequestsRoutes,
    meta: { role: Role.BORROWER }
  }
];
