import { ArchivedProjectProvider } from "../ArchivedProjectProvider";
import { ApiClientFactory } from "./clients";

export class ArchivedProviderFactory {
    private readonly apiClientFactory: ApiClientFactory;

    constructor(baseUrl: string) {
        this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/project/archived`);
    }

    createArchivedProjectProvider(authToken: string) : ArchivedProjectProvider {
        return new ArchivedProjectProvider(
            this.apiClientFactory.createAuthorizedApiClient(authToken)
        );
    }
}