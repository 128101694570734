import { ReportManager } from "../ReportManager";
import { ApiClientFactory } from "./clients";

export class ReportManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/report`);
  }

  createReportManager(authToken: string): ReportManager {
    return new ReportManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
