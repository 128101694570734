import {
  createAuthTokenManager,
  createVisitManager
} from "@/utils/di";
import { v4 as uuidV4 } from "uuid";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized
} from "vue-router";
import authRoutes from "../components/auth/router";
import dashboardRoutes from "../components/dashboard/router";

const routes = [
  { path: "/", redirect: { name: "dashboard" }, name: "home" },
  {
    path: "/auth",
    component: (): Promise<{}> => import("../components/auth/Auth.vue"),
    children: authRoutes
  },
  {
    path: "/dashboard",
    component: (): Promise<{}> => import("../components/dashboard/Dashboard.vue"),
    children: dashboardRoutes,
    name: "dashboard"
  },
  {
    path: "/:pathMatch(.*)*",
    component: import("../components/errors/components/Page404.vue"),
    name: "pageNotFound"
  }
];

function addVisit(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
  try {
    const basePath = window.location.protocol + "//" + window.location.host;
    const data = localStorage.getItem("visits");
    const visits: Array<any> = data ? JSON.parse(data) : [];
    visits.push({
      uuid: uuidV4(),
      userAgent: window.navigator.userAgent,
      url: basePath + to.fullPath,
      referrer: from.fullPath && from.fullPath !== "/" ? (basePath + from.fullPath) : document.referrer,
      createdAt: new Date().toISOString()
    });
    localStorage.setItem("visits", JSON.stringify(visits));
  } catch (error) {
    console.error(error)
  }
}

async function sendVisits(): Promise<void> {
  try {
    const visitManager = await createVisitManager();
    const data = localStorage.getItem("visits");
    const visits: Array<any> = data ? JSON.parse(data) : [];
    await visitManager.addVisits(...visits);
    localStorage.setItem("visits", JSON.stringify([]));
  } catch (error) {
    console.error(error)
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  addVisit(to, from);
  if ((to.meta && to.meta.requiresAuth === false) || await createAuthTokenManager().has()) {
    next();
  } else {
    sessionStorage.setItem("redirectTo", to.path);
    next({ name: "login" });
  }
});

router.afterEach(() => sendVisits());

export default router;
