import { ApiClient } from "./clients";
import { NotificationSettings } from "./models/notification/NotificationSettings";

export class NotificationSettingsManager {
  constructor(private readonly apiClient: ApiClient) {}

  getNotificationSettings(): Promise<NotificationSettings> {
    return this.apiClient.get("");
  }

  putNotificationSettings(notificationSettings: NotificationSettings): Promise<void> {
    return this.apiClient.post("", notificationSettings);
  }
}
