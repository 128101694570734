export default {
  get dsn(): string | undefined {
    return process.env.VUE_APP_SENTRY_DSN;
  },
  get environment(): string | undefined {
    return process.env.VUE_APP_SENTRY_ENVIRONMENT;
  },
  get release(): string | undefined {
    return process.env.VUE_APP_SENTRY_RELEASE;
  }
};
