import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { UuidResult } from "./models/common/UuidResult";
import { Collateral } from "./models/project-request/Collateral";
import { Documents } from "./models/project-request/Documents";
import { FundingRequest } from "./models/project-request/FundingRequest";
import { General } from "./models/project-request/General";
import { Ownership } from "./models/project-request/Ownership";
import { ProjectRequest } from "./models/project-request/ProjectRequest";

export class ProjectRequestManager {
  constructor(private readonly apiClient: ApiClient) {}

  getList(page = 1, perPage = 25): Promise<Collection<ProjectRequest>> {
    return this.apiClient.get(`/?page=${page}&perPage=${perPage}`);
  }

  create(fundingRequest: FundingRequest): Promise<UuidResult> {
    return this.apiClient.post("", fundingRequest);
  }

  getGeneral(uuid: string): Promise<General> {
    return this.apiClient.get(`/${uuid}/general`);
  }

  setGeneral(uuid: string, general: General): Promise<void> {
    return this.apiClient.post(`/${uuid}/general`, general);
  }

  getOwnership(uuid: string): Promise<Ownership> {
    return this.apiClient.get(`/${uuid}/ownership`);
  }

  setOwnership(uuid: string, ownership: Ownership): Promise<void> {
    return this.apiClient.post(`/${uuid}/ownership`, ownership);
  }

  getCollaterals(uuid: string): Promise<Array<Collateral>> {
    return this.apiClient.get(`/${uuid}/collaterals`);
  }

  setCollaterals(uuid: string, collaterals: Array<Collateral>): Promise<void> {
    return this.apiClient.post(`/${uuid}/collaterals`, collaterals);
  }

  getDocuments(uuid: string): Promise<Documents> {
    return this.apiClient.get(`/${uuid}/documents`);
  }

  setDocuments(uuid: string, documents: Documents): Promise<void> {
    return this.apiClient.post(`/${uuid}/documents`, documents);
  }

  publish(uuid: string): Promise<void> {
    return this.apiClient.post(`/${uuid}/publish`);
  }
}
