const m: any = window;
const e = document;
const t = "script";
const r = "https://mc.yandex.ru/metrika/tag.js";
const i = "ym";

export function initYandexMetrika(): void {
  m[i] = m[i] || function (...data: Array<string>) {
    (m[i].a = m[i].a || []).push(data)
  };
  m[i].l = +new Date();
  const k: any = e.createElement(t)
  const a = e.getElementsByTagName(t)[0];
  k.async = 1;
  k.src = r;
  if (a && a.parentNode) {
    a.parentNode.insertBefore(k, a)
  } else {
    e.appendChild(k);
  }
}

export function configYandexMetrika(id: string, userId?: string): void {
  if (userId) {
    m[i](id, "userParams", {
      UserID: userId
    });
  }
  m[i](id, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "dataLayer"
  });
}
