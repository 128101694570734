type Subscriber<T> = (message: T) => void;

export class PubSub<T> {
  private readonly subscribers: { [key: string]: Subscriber<T> } = {};

  subscribe(subscriber: Subscriber<T>): string {
    const token = (+new Date()).toString() + Math.floor(Math.random() * 1000);
    this.subscribers[token] = subscriber;
    return token;
  }

  unsubscribe(token: string): void {
    delete this.subscribers[token];
  }

  publish(message: T): void {
    for (const key in this.subscribers) {
      this.subscribers[key](message);
    }
  }
}
