export default {
  get publicBaseUrl(): string {
    return process.env.VUE_APP_PUBLIC_BASE_URL || "";
  },
  get minInvestmentPrimary(): number {
    return parseInt(process.env.VUE_APP_MIN_INVESTMENT_PRIMARY) || 0;
  },
  get minInvestmentSecondary(): number {
    return parseInt(process.env.VUE_APP_MIN_INVESTMENT_SECONDARY) || 0;
  },
  get maxInvestment(): number {
    return parseInt(process.env.VUE_APP_MAX_INVESTMENT) || 0;
  },
  get minWithdraw(): number {
    return parseInt(process.env.VUE_APP_MIN_WITHDRAW) || 0;
  },
  get minReferralWithdraw(): number {
    return parseInt(process.env.VUE_APP_MIN_REFERRAL_WITHDRAW) || 0;
  },
  get guarantorFeature(): boolean {
    return !!process.env.VUE_APP_GUARANTOR_FEATURE;
  },
  get reportsFeature(): boolean {
    return !!process.env.VUE_APP_REPORTS_FEATURE;
  },
  get lendlyInvestUserUuid(): string {
    return process.env.VUE_APP_LENDLY_INVEST_USER_UUID;
  },
  get standardProjectUuid(): string {
    return process.env.STANDARD_PROJECT_UUID;
  },
  get signatureTurnedOff(): boolean {
    return !!process.env.VUE_APP_TURN_OFF_SIGNATURE;
  },
};
