import { PubSub } from "@/utils/pub-sub";
import { App } from "@vue/runtime-core";

type Message = {
  message: string;
  resolve?: () => void;
};

type Callback = (message: Message) => void;

class Confirm {
  private readonly pubSub: PubSub<Message>;

  constructor() {
    this.pubSub = new PubSub<Message>();
  }

  show(message: string, resolve?: () => void): void {
    this.pubSub.publish({ message, resolve });
  }

  subscribe(callback: Callback): string {
    return this.pubSub.subscribe(callback);
  }

  unsubscribe(token: string): void {
    this.pubSub.unsubscribe(token);
  }
}

export default {
  install: (app: App) => {
    const c = new Confirm();
    app.config.globalProperties.$confirm = (message: string, resolve?: () => void): void => {
      c.show(message, resolve);
    };
    app.config.globalProperties.$confirm.subscribe = (callback: Callback): string => {
      return c.subscribe(callback)
    }
    app.config.globalProperties.$confirm.unsubscribe = (token: string): void => {
      c.unsubscribe(token)
    }
  }
};
