import { ProfileManager } from "../ProfileManager";
import { ApiClientFactory } from "./clients";

export class ProfileManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string, private readonly useGetCache: boolean = false) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/profile`);
  }

  createProfileManager(authToken: string): ProfileManager {
    return new ProfileManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken),
      this.useGetCache
    );
  }
}
