import { IdentificationManager } from "../IdentificationManager";
import { ApiClientFactory } from "./clients";

export class IdentificationManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/identification`);
  }

  createIdentificationManager(authToken: string): IdentificationManager {
    return new IdentificationManager(
      this.apiClientFactory.createAuthorizedApiClient(authToken));
  }
}
