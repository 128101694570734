import { ApiClient } from "./clients";

type Context = {
  readonly projectUuid?: string;
};

export class CallbackRequestManager {
  constructor(private readonly apiClient: ApiClient) {}

  create(context?: Context): Promise<void> {
    return this.apiClient.post("", { context });
  }
}
