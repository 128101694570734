import { ReferralProfile } from "@/utils/api-client/models/referral/ReferralProfile";
import { ReferralTransaction } from "@/utils/api-client/models/referral/ReferralTransaction";
import { ReferralUser } from "@/utils/api-client/models/referral/ReferralUser";
import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import {PaymentType} from "@/utils/api-client/enums";
import {buildQuery} from "@/utils/api-client/utils";


type ReferralTransactionFilter = {
  dateFrom?: Date;
  dateTo?: Date;
  paymentTypes?: Array<PaymentType>;
  referralUuids?: Array<string>;
};


export class ReferralManager {
  constructor(private readonly apiClient: ApiClient) {}

  getProfile(): Promise<ReferralProfile> {
    return this.apiClient.get("/profile");
  }

  markAccepted(): Promise<void> {
    return this.apiClient.post("/accepted");
  }

  getUserList(page = 1, perPage = 25): Promise<Collection<ReferralUser>> {
    return this.apiClient.get(`/user?page=${page}&perPage=${perPage}`);
  }

  getTransactionList(filter: ReferralTransactionFilter ,page = 1, perPage = 25): Promise<Collection<ReferralTransaction>> {
    return this.apiClient.get(`/transaction?${buildQuery(filter, "filter")}&page=${page}&perPage=${perPage}`);
  }
}
