import { analyticsConfig } from "@/configs";
import {
  configGoogleTagManager,
  initGoogleTagManager
} from "@/plugins/analytics/google-tag-manager";
import {
  initYandexMetrika,
  configYandexMetrika
} from "@/plugins/analytics/yandex-metrika";
import { initGoogleAnalytics } from "./google-analytics";
import { App } from "@vue/runtime-core";

class Analytics {
  private readonly googleAnalyticsId = analyticsConfig.googleAnalyticsId;
  private readonly googleTagManagerId = analyticsConfig.googleTagManagerId;
  private readonly yandexMetrikaId = analyticsConfig.yandexMetrikaId;

  constructor() {
    if (this.googleAnalyticsId) {
      initGoogleAnalytics(this.googleAnalyticsId);
    }
    if (this.googleTagManagerId) {
      initGoogleTagManager(this.googleTagManagerId);
    }
    if (this.yandexMetrikaId) {
      initYandexMetrika();
    }
  }

  setUserId(paymentCode: string): void {
    if (this.googleTagManagerId) {
      configGoogleTagManager(this.googleTagManagerId, paymentCode);
    }
    if (this.yandexMetrikaId) {
      configYandexMetrika(this.yandexMetrikaId, paymentCode);
    }
  }

  setGuest(): void {
    if (this.googleTagManagerId) {
      configGoogleTagManager(this.googleTagManagerId);
    }
    if (this.yandexMetrikaId) {
      configYandexMetrika(this.yandexMetrikaId);
    }
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$analytics = new Analytics();
  }
};
