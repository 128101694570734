import { MyMoneyProvider } from "../MyMoneyProvider";
import { ApiClientFactory } from "./clients";

export class MyMoneyProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/my-money`);
  }

  createMyMoneyProvider(authToken: string): MyMoneyProvider {
    return new MyMoneyProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
