import { apiClientConfig } from "@/configs";
import * as apiClient from "@/utils/api-client";
import { createAuthTokenManager } from "@/utils/di/auth";

function getAuthToken(): Promise<string> {
  return createAuthTokenManager().get();
}

export async function createArchivedProjectProvider(): Promise<apiClient.ArchivedProjectProvider> {
  const factory = new apiClient.ArchivedProviderFactory(apiClientConfig.baseUrl);
  return factory.createArchivedProjectProvider(await getAuthToken());
}

export async function createAgreementProvider(): Promise<apiClient.AgreementProvider> {
  const factory = new apiClient.AgreementProviderFactory(apiClientConfig.baseUrl);
  return factory.createAgreementProvider(await getAuthToken());
}

export async function createCallbackRequestManager(): Promise<apiClient.CallbackRequestManager> {
  const factory = new apiClient.CallbackRequestManagerFactory(apiClientConfig.baseUrl);
  return factory.createCallbackRequestManager(await getAuthToken());
}

export async function createCompanyProvider(): Promise<apiClient.CompanyProvider> {
  const factory = new apiClient.CompanyProviderFactory(apiClientConfig.baseUrl);
  return factory.createCompanyProvider(await getAuthToken());
}

export async function createDayRepaymentProvider(): Promise<apiClient.DayRepaymentProvider> {
  const factory = new apiClient.DayRepaymentProviderFactory(apiClientConfig.baseUrl);
  return factory.createDayRepaymentProvider(await getAuthToken());
}

export async function createIdentificationManager(): Promise<apiClient.IdentificationManager> {
  const factory = new apiClient.IdentificationManagerFactory(apiClientConfig.baseUrl);
  return factory.createIdentificationManager(await getAuthToken());
}

export async function createInvestmentManager(): Promise<apiClient.InvestmentManager> {
  const factory = new apiClient.InvestmentManagerFactory(apiClientConfig.baseUrl);
  return factory.createInvestmentManager(await getAuthToken());
}

export async function createMyMoneyProvider(): Promise<apiClient.MyMoneyProvider> {
  const factory = new apiClient.MyMoneyProviderFactory(apiClientConfig.baseUrl);
  return factory.createMyMoneyProvider(await getAuthToken());
}

export async function createNotificationSettingsManager(): Promise<apiClient.NotificationSettingsManager> {
  const factory = new apiClient.NotificationSettingsManagerFactory(apiClientConfig.baseUrl);
  return factory.createNotificationSettingsManager(await getAuthToken());
}

export async function createOfferProvider(): Promise<apiClient.OfferProvider> {
  const factory = new apiClient.OfferProviderFactory(apiClientConfig.baseUrl);
  return factory.createOfferProvider(await getAuthToken());
}

export async function createOfferPurchaseManager(): Promise<apiClient.OfferPurchaseManager> {
  const factory = new apiClient.OfferPurchaseManagerFactory(apiClientConfig.baseUrl);
  return factory.createOfferPurchaseManager(await getAuthToken());
}

export async function createOfferSaleManager(): Promise<apiClient.OfferSaleManager> {
  const factory = new apiClient.OfferSaleManagerFactory(apiClientConfig.baseUrl);
  return factory.createOfferSaleManager(await getAuthToken());
}

export async function createProfileManager(): Promise<apiClient.ProfileManager> {
  const factory = new apiClient.ProfileManagerFactory(apiClientConfig.baseUrl);
  return factory.createProfileManager(await getAuthToken());
}

export async function createProjectProvider(): Promise<apiClient.ProjectProvider> {
  const factory = new apiClient.ProjectProviderFactory(apiClientConfig.baseUrl);
  return factory.createProjectProvider(await getAuthToken());
}

export async function createProjectRequestManager(): Promise<apiClient.ProjectRequestManager> {
  const factory = new apiClient.ProjectRequestManagerFactory(apiClientConfig.baseUrl);
  return factory.createProjectRequestManager(await getAuthToken());
}

export async function createReferralManager(): Promise<apiClient.ReferralManager> {
  const factory = new apiClient.ReferralManagerFactory(apiClientConfig.baseUrl);
  return factory.createReferralManager(await getAuthToken());
}

export async function createReferralWithdrawManager(): Promise<apiClient.ReferralWithdrawManager> {
  const factory = new apiClient.ReferralWithdrawManagerFactory(apiClientConfig.baseUrl);
  return factory.createReferralWithdrawManager(await getAuthToken());
}

export async function createRepaymentProvider(): Promise<apiClient.RepaymentProvider> {
  const factory = new apiClient.RepaymentProviderFactory(apiClientConfig.baseUrl);
  return factory.createRepaymentProvider(await getAuthToken());
}

export async function createTransactionProvider(): Promise<apiClient.TransactionProvider> {
  const factory = new apiClient.TransactionProviderFactory(apiClientConfig.baseUrl);
  return factory.createTransactionProvider(await getAuthToken());
}

export async function createReportManager(): Promise<apiClient.ReportManager> {
  const factory = new apiClient.ReportManagerFactory(apiClientConfig.baseUrl);
  return factory.createReportManager(await getAuthToken());
}

export async function createUploadManager(): Promise<apiClient.UploadManager> {
  const factory = new apiClient.UploadManagerFactory(apiClientConfig.baseUrl);
  return factory.createUploadManager(await getAuthToken());
}

export async function createWithdrawManager(): Promise<apiClient.WithdrawManager> {
  const factory = new apiClient.WithdrawManagerFactory(apiClientConfig.baseUrl);
  return factory.createWithdrawManager(await getAuthToken());
}

export async function createFeeSettingsProvider(): Promise<apiClient.FeeSettingsProvider> {
  const factory = new apiClient.FeeSettingsFactory(apiClientConfig.baseUrl);
  return factory.createFeeSettingsProvider(await getAuthToken());
}

export async function createVisitManager(): Promise<apiClient.VisitManager> {
  let authToken;
  try {
    authToken = await getAuthToken();
  } catch (e) {
    // Non authorized
  }
  const factory = new apiClient.VisitManagerFactory(apiClientConfig.baseUrl);
  return factory.createVisitManager(authToken);
}
