import { ApiClient } from "../../clients";

export class ApiClientFactory {
  constructor(private readonly baseUrl: string) {}

  createApiClient(): ApiClient {
    return new ApiClient(this.baseUrl);
  }

  createAuthorizedApiClient(authToken: string): ApiClient {
    return new ApiClient(this.baseUrl, authToken);
  }
}
