import { ApiClient } from "./clients";
import { EntrepreneurIdentification } from "./models/identification/EntrepreneurIdentification";
import { IndividualIdentification } from "./models/identification/IndividualIdentification";
import { LegalEntityIdentification } from "./models/identification/LegalEntityIdentification";

export class IdentificationManager {
  constructor(private readonly apiClient: ApiClient) {}

  findOne(): Promise<(IndividualIdentification & EntrepreneurIdentification & LegalEntityIdentification) | undefined> {
    return this.apiClient.get("");
  }

  createIndividualIdentification(identification: IndividualIdentification): Promise<void> {
    return this.apiClient.post("/individual", {identification});
  }

  createEntrepreneurIdentification(identification: EntrepreneurIdentification): Promise<void> {
    return this.apiClient.post("/entrepreneur", {identification});
  }

  createLegalEntityIdentification(identification: LegalEntityIdentification): Promise<void> {
    return this.apiClient.post("/legal-entity", {identification});
  }
}
