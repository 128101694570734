import { ApiClient } from "./clients";
import {
  PaymentType,
  TransactionType
} from "./enums";
import { Collection } from "./models/common/Collection";
import { UrlResult } from "./models/common/UrlResult";
import { DetailedTransaction } from "./models/transaction/DetailedTransaction";
import { Transaction } from "./models/transaction/Transaction";
import { buildQuery } from "./utils";

type ListFilter = {
  dateFrom?: Date;
  dateTo?: Date;
  paymentTypes?: Array<PaymentType>;
  transactionTypes?: Array<TransactionType>;
};

type ExportStatementFilter = {
  dateFrom?: string;
  dateTo?: string;
}

export class TransactionProvider {
  constructor(private readonly apiClient: ApiClient) {}

  getList(filter: ListFilter, page = 1, perPage = 25): Promise<Collection<Transaction>> {
    return this.apiClient.get(`?${buildQuery(filter, "filter")}&page=${page}&perPage=${perPage}`);
  }

  getOne(uuid: string): Promise<DetailedTransaction> {
    return this.apiClient.get(`/${uuid}`);
  }

  exportStatement(filter: ExportStatementFilter): Promise<UrlResult> {
    return this.apiClient.get(`/export-statement?${buildQuery(filter, "filter")}`);
  }
}
