import { AgreementProvider } from "../AgreementProvider";
import { ApiClientFactory } from "./clients";

export class AgreementProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/agreement`);
  }

  createAgreementProvider(authToken: string): AgreementProvider {
    return new AgreementProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
