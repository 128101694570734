import { OfferProvider } from "../OfferProvider";
import { ApiClientFactory } from "./clients";

export class OfferProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string) {
    this.apiClientFactory = new ApiClientFactory(`${baseUrl}/api/v1/offer`);
  }

  createOfferProvider(authToken: string): OfferProvider {
    return new OfferProvider(
      this.apiClientFactory.createAuthorizedApiClient(authToken)
    );
  }
}
