import { ApiClient } from "./clients";
import { Collection } from "./models/common/Collection";
import { InvestmentRepayments } from "./models/repayment/InvestmentRepayments";

export class RepaymentProvider {
  constructor(private readonly apiClient: ApiClient) {}

  getList(page = 1, perPage = 25): Promise<Collection<InvestmentRepayments>> {
    return this.apiClient.get(`?page=${page}&perPage=${perPage}`);
  }
}
